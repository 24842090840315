* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  background-color: whitesmoke;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  opacity: 90%;
}

.navbar-item {
  display: flex;
  margin-right: 15px;
  margin-top: 1vh;
}

.navbar-link {
  text-decoration: none;
}

.navbar-text:hover {
  color: rgb(121, 197, 197);
}

.navbar-text {
  text-decoration: none;
  font-size: medium;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-align: center;
  color: white;
}

.scroll-navbar-text:hover {
  color: rgb(121, 197, 197);
}

.scroll-navbar-text {
  color: black;
  text-decoration: none;
  font-size: medium;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-align: center;
}

#intro-picture-container {
  position: relative;
  width: 100%;
}

#intro-picture {
  height: 100vh;
  width: 100%;
}

#about-me {
  height: 30vh;
  width: 25vw;
  text-align: center;
  color: #fff;
}

#about-me-title {
  height: 6vh;
  display: flex;
  justify-content: flex-start;
  text-align: initial;
}

#about-me-role {
  display: flex;
}

#about-me-divider {
  width: 20%;
}

#about-me-body {
  text-align: left;
  height: 13vh;
  width: 30vw;
}

.about-me-icons li {
  display: list-item;
  vertical-align: middle;
  margin: 15px;
}

.navbar-icons li {
  margin: 15px;
  margin-top: -3px;
}

.about-me-icons li:hover {
  color: aqua;
}

#cards-conatiner {
  width: 100%;
  position: absolute;
  top: 85%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#cards {
  display: flex;
  flex-direction: row;
  height: 710px;
  width: 100%;
  position: absolute;
  margin-top: 80vh;
  padding: 0px 120px;
}

.card {
  width: 100%;
  height: 100%;

  text-align: center;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #eeefef;
  overflow: hidden;
  box-shadow: rgb(140 152 164 / 25%) 0px 3px 6px 0px;
  color: black;
  margin: 2vh 2vw;

  background-color: white;

  padding: 30px;
}

.card-body {
  height: 29vh;

  display: flex;
  flex-direction: column;
  font-family: "Inter, sans-serif";
  color: rgb(103, 119, 136);
  font-size: 18px;
}

.card-title {
  margin-bottom: 30px;
  color: rgb(30, 32, 34);
}

.card-technologies {
  line-height: 2.5vh;
}

.technologies-and-tools {
  margin-bottom: 2vh;
  color: rgb(30, 32, 34);
}

.capability {
  list-style-type: none;
  font-family: "Inter, sans-serif";
  color: rgb(103, 119, 136);
  font-size: 18px;
}

.tool {
  list-style-type: none;
  font-family: "Inter, sans-serif";
  color: rgb(103, 119, 136);
  font-size: 17px;
}

#bottom-section {
  margin-top: 35vh;
  width: 100%;
  height: 85rem;
  background: linear-gradient(to left top, rgb(27, 36, 48), #443b38);
}

#projects-container {
  width: 100%;
  top: 195vh;
  position: absolute;
  padding: 0px 125px;
}

.project {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  border: 0.3vh solid black;
  border-radius: 2vh;
  background: white;

  text-align: left;
  margin: 0px 25px;
}

#projects-title {
  position: absolute;
  top: 170vh;
  width: 100%;
}

#projects-title-text {
  font-size: 6vh;
  font-family: Inter, sans-serif;
  color: whitesmoke;
  margin-bottom: 15px;
  font-weight: bold;
}

#projects-subtitle-text {
  font-size: 3vh;
  font-family: Inter, sans-serif;
  color: whitesmoke;
  margin-top: 0px;
}

.project-details {
  /* align-self: flex-start; */
  width: 80%;
  opacity: 0;
}

footer {
  background-color: whitesmoke;
  height: 6vh;
  margin-top: 3vh;
}

.icon-container {
  height: 6vh;
  width: 4vw;
  border-radius: 3vh;
  background-color: whitesmoke;
  margin: 2vh 2vw;
  border: 0.3vh solid rgb(16, 0, 94);
}

.about-me-icon:hover {
  color: rgb(121, 197, 197);
}

.about-me-icon {
  height: 4vh;
  width: 2vw;
  color: white;
}

.navbar-icon:hover {
  color: rgb(121, 197, 197);
}

.navbar-icon {
  height: 4vh;
  width: 2vw;
  color: black;
}

.navbar-button {
  padding: 0px 5vh;
}

.project-container {
  height: auto;
  width: 100%;
  position: relative;
  margin: 10px 10px 10px 10px;
}

.project-container:hover img {
  opacity: 0.1;
}

.project-container:hover .project-details {
  opacity: 1;
}

.content {
  position: absolute;
  width: 100%;
  padding: 2vh 2vw;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  overflow: hidden;
}

.content p {
  font-family: Inter, sans-serif;
  color: white;
  text-align: center;
  /* padding: 0px 8vh; */
}

.content #project-links .project-link a {
  display: block;
  text-decoration: none;
}

.project-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  border-radius: 1.5vh;
  /* margin-top: 1vh;
  height: 25vh;
  width: 23vw;

  border: 0.2vh solid black;
  border-radius: 2vh; */

  box-shadow: whitesmoke 0px -3px 8px 0px;
}

#project-technologies {
  display: flex;
  justify-content: center;
}

.project-technology {
  height: 100%;
  width: 100%;
  background-color: rgb(197, 197, 197);
  color: whitesmoke;
  border-radius: 1vh;
  margin: 0.05vh 0.2vw;
}

.project-technology p {
  position: relative;
  color: black;
}

#project-links {
  display: flex;
  justify-content: center;
}

.project-link {
  background-color: white;
  border-radius: 1vh;
  height: 4.2vh;
  width: 8vw;
  margin: 1vh 0.2vw;
}

.project-link:hover {
  background-color: rgb(121, 197, 197);
}

.project-link a p {
  color: black;
}

.project-button {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 1vh;
  /* height: 4.2vh;
  width: 8vw; */
  margin: 1vh 0.2vw;
}

.project-button a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-family: Inter, sans-serif;
}

.project-link-name {
  width: 2vw;
}
